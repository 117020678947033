<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
    <FooterBottom></FooterBottom>
  </div>
</template>


<script>
import Header from 'components/Header.vue'
import Footer from 'components/Footer.vue'
import FooterBottom from 'components/FooterBottom.vue'
export default {
  name: "app",
  components: {
    Header,
    Footer,
    FooterBottom,
  },
  data() {
    return {};
  },
  watch: {},
  created() {

  },
  methods: {},
  computed: {}
};
</script>
<style lang="less">
body{
  margin:0;
  padding: 0;
  .popover-self{
    min-width: 100px;
    padding: 12px 0;
    .inner{
      text-align: center;
      font-size: 16px;
      padding: 4px 0;
      color: #313132;
      height: 24px;
      line-height: 24px;
      cursor: pointer;
      &.active{
        color: #690409;
      }
    }
  }
}
#app {
  margin:0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  width: 100%;
  height: 100vh;
  // overflow: hidden;
}

</style>
