<template>
  <div class="basicBox">
    <div class="goodsBox" v-for="(item,index) of spbj" :key="index">
      <div class="imgbox">
        <img :src="item.src" alt="">
      </div>
      <Title :title="item.name" fontSize="16px" color="#313132" height="20px" lineHeight="20px" class="title"></Title>
    </div>
  </div>
</template>

<script>
import Title from 'components/Title.vue'
import {spbj} from '../utils/newItems'
export default {
  name: 'Spbj',
  components: {
    Title,
  },
  data(){
    return{
      spbj,
    }
  },
  methods:{

  }
}
</script>
<style scoped lang="less">
.basicBox{
  margin: 0;
  width: 70%;
  padding: 50px 15% 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  // align-content: space-between;
  .goodsBox{
    cursor: pointer;
    width: 30%;
    text-align: center;
    margin: 40px 20px;
    
    .imgbox{
      width: 100%;
      height: 260px;
      border: 1px solid #939396;
      margin-bottom: 30px;
      img{
        width: 60%;
        height: auto;
      }
    }
    .title{
      width: 100%;
    }
  }
}
</style>