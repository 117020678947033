<template>
  <div class="basicBox">
    <!-- 首屏大图 -->
    <div class="first-screen">
    </div>
    <!-- 简介 -->
    <div class="common first-content" >
      <Watermark title="Company" color="#EBE9E6"></Watermark>
      <Title title="复盛公是一家现代化药业集团" fontSize="36px" color="#313132" height="50px" lineHeight="50px" class="commonLeft"></Title>
      <ItemNew item="拥有完善的管理体系、现代化GMP生产基地、GSP物流基地、全国性健全的营销网络，同时拥有优质配套的物流系统，有口皆碑的商业信誉以及有目共睹的知名度。核心业务从全国总代理拓展到医药工业、终端营销以及电子商务，并致力于生产经营，服务创造价值。" fontSize="20px" color="#939396" lineHeight="28px" class="commonLeft item"></ItemNew>
      <div class="imgBox">
        <ImgBox1 title="公司简介" usrc="1" ></ImgBox1>
        <ImgBox1 title="历程" usrc="2"></ImgBox1>
        <ImgBox1 title="企业文化" usrc="3"></ImgBox1>
      </div>
    </div>
    <!-- 新闻 -->
    <div class="common news">
      <Watermark title="News" color="#EBE9E6"></Watermark>
      <Title title="新闻公告" fontSize="36px" color="#313132" height="50px" lineHeight="50px" class="commonLeft"></Title>
      <ItemNew item="核心业务从全国总代理拓展到医药工业、终端营销以及电子商务，并致力于生产、经营，服务创造价值。" fontSize="20px" color="#939396" height="28px" lineHeight="28px" class="commonLeft item"></ItemNew>
      <div class="contentBox">
        <img src="../../static/home/news.jpg" class="leftImg" alt="">
        <div class="rightShow">
          <NewsItem id="0"></NewsItem>
          <NewsItem id="1"></NewsItem>
          <NewsItem id="2"></NewsItem>
          <NewsItem id="3"></NewsItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Watermark from 'components/Watermark.vue'
import Title from 'components/Title.vue'
import ItemNew from 'components/ItemNew.vue'
import ImgBox1 from 'components/imgBox1.vue'
import NewsItem from 'components/NewsItem.vue'
export default {
  name: 'Home',
  components: {
    Watermark,
    Title,
    ItemNew,
    ImgBox1,
    NewsItem
  }
}
</script>
<style scoped lang="less">
.basicBox{
  width: 100%;
  box-sizing: border-box;
  .first-screen{
    position: relative;
    width: 100%;
    height: 94vh;
    background: url('../assets/home/主图1.png') no-repeat;
    background-size: 100% 100%;
    .nameBox{
      position: absolute;
      top: 28vh;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      height: 70px;
      font-size: 70px;
      font-family: YouYuan;
      color: #FFFFFF;
      letter-spacing: 8px;
    }
  }
  .common{
    position: relative;
    width: 70%;
    padding: 80px 15% 50px;
    .commonLeft{
      margin-left: 30px;
    }
    .item{
      margin: 10px 0 40px 30px;
    }
  }
  .first-content{
    cursor: pointer;
    background: #FFFFFF;
    .imgBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        margin: 0 5px;
      }
    }
  }
  .selectUs{
    background: #222120;
    .imgBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div{
        margin: 0 10px;
      }
    }
  }
  .news{
    background: #FFFFFF;
    .contentBox{
      display: flex;
      .leftImg{
        width: 40%;
        height: 300px;
      }
      .rightShow{
        width: 60%;
        margin-left: 50px;
      }
    }
  }
}
</style>