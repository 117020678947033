<template>
  <div class="basicBox">
    <Title title="联系我们" fontSize="20px" color="#313132" height="28px" lineHeight="28px" class="title"></Title>
    <div class="mapbox">
      <img src="../../static/home/map1.png" alt="">
    </div>
    <div class="item" v-for="(v,index) of contactItems" :key="index">{{v.name}}</div>
  </div>
</template>

<script>
import Title from 'components/Title.vue'
export default {
  name: 'Example',
  components: {
    Title,
  },
  data(){
    return{
      contactItems:[
        {name:'地址：山西省晋中市祁县经济开发区朝阳西街4号'},
        {name:'邮编：030600'},
        {name:'人事招聘热线：17703435921'},
        {name:'新特药服务热线：13503542800'},
        {name:'品牌丸剂销售热线：18535529777'},
        {name:'24小时服务热线：400-100-8189'},
      ],
    }
  },
  methods:{

  }
}
</script>
<style scoped lang="less">
.basicBox{
  margin: 0;
  width: 70%;
  padding: 50px 15% 100px;
  .mapbox{
    margin: 30px 0;
    width: 100%;
    height: 600px;
  }
  img{
    width: 100%;
    height: 100%;
  }
  .item{
    font-size: 16px;
    color: #939396;
    height: 40px;
    line-height: 40px;
  }
}
</style>