<template>
  <div class="conBox" :style="{color:color}"><i>{{title}}</i>
  </div>
</template>

<script>
/**
 * 水印效果
 * 支持水印字和水印字颜色传入
 */
export default {
  name: 'Watermark',
  props:{
    title:{
      type:String
    },
    color:{
      type:String
    }
  }
}
</script>

<style scoped lang="less">
.conBox{
  position: absolute;
  top: 40px;
  left: 15%;
  height: 72px;
  font-size: 64px;
  font-family: Arial-BoldItalicMT, Arial;
  font-weight: normal;
  color: #EBE9E6;
  line-height: 74px;
  z-index: 0;
}
</style>
