<template>
  <div class="conBox" :style="{fontSize:fontSize,color:color,lineHeight:lineHeight,height:height}" :class="[overStyle=='2'?'overHandle2':'',overStyle=='3'?'overHandle3':'']" @click="toOtherSize">
    {{item}}
  </div>
</template>

<script>
/**
 * 内容
 * 支持内容、颜色，字体大小、高，行高，及多行省略，多行省略默认关闭，支持2或3行
 */
export default {
  name: 'Item',
  props:{
    toUrl:{
      type:String,
    },
    overStyle:{
      type:String,
    },
    item:{
      type:String
    },
    color:{
      type:String,
      default:'#C0C0C0'
    },
    fontSize:{
      type:String,
      default:'14px'
    },
    height:{
      type:String,
    },
    lineHeight:{
      type:String,
      default:'42px'
    }
  },
  methods:{
    toOtherSize(){
      if(this.$route.path==='/'+this.toUrl){
        window.scroll({top:0, left:0, behavior: 'smooth'})
      }else{
        this.$router.push('/'+this.toUrl)
      }
    }
  }
}
</script>

<style scoped lang="less">
.conBox{
  font-size: 16px;
  font-weight: 400;
  color: #C0C0C0;
  position: relative;
  z-index: 5;
}
.overHandle2{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;      
}
.overHandle3{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;      
}
</style>
