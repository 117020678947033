<template>
  <div class="basicBox">
    <div class="left-name">
      <img src="@/assets/home/logo.png" alt="">
    </div>
    <div class="right-nav">
      <div class="navBox" v-for="(item,index) of this.navs" :key="index">
        <el-popover
          popper-class="popover-self"
          v-model="item.show"
          :disabled="item.children.length===0"
          placement="bottom"
          trigger="hover">
          <div class="inner" v-for="(inner,index1) of item.children" :key="index1" :class="curPage===inner.value?'active':''" @click="toNav(inner.value)">{{inner.name}}</div>
          <div slot="reference" class="navText" :class="curPage===item.value?'active':''" @click="toNav(item.value)">{{item.name}}</div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 页面头部
 */
export default {
  name: 'Header',
  data(){
    return{
      navs:[
        {value:'',name:'首页', show:false, children:[]},
        {value:'company',name:'公司简介', show:false,children: [
          {value:'intro',name:'简介'},
          {value:'process',name:'历程'},
          {value:'culture',name:'企业文化'},
        ]},
        {value:'news',name:'新闻动态', show:false,children: [
          {value:'enterprise',name:'企业新闻'},
          {value:'business',name:'行业资讯'},
        ]},
        {value:'products',name:'商品展示', show:false,children: [
          {value:'zxcy',name:'中西成药'},
          {value:'ylqx',name:'医疗器械'},
          {value:'spbj',name:'食品保健'},
        ]},
        {value:'contact',name:'联系我们',show:false,children:[]}
      ]
    }
  },
  computed:{
    curPage(){
      return this.$store.state.curPage
    },
    curTwoPages(){
      let arr = []
      this.navs.forEach(v=>{
        if(v.children){
          arr = arr.concat(v.children)
        }
      })
      return arr
    }
  },
  created(){
  },
  methods:{ 
    toNav(val){ 
      if(val===this.curPage){
        return 
      }else{
        let item = this.navs.find(v=>v.value===val)
        if(item){
          let route = ''
          if(item.children && item.children.length>0){
            route = '/' + item.children[0].value
          }else{
            route = '/' + val
          }
          
          this.$router.push(route)
        }else{
          let twoItem = this.curTwoPages.find(v=>v.value===val)
          if(twoItem){
            let route = '/' + val
            
            this.$router.push(route)
          }
        }
      }
    },
  }
}
</script>

<style scoped lang="less">
.basicBox{
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 6vh;
  padding-bottom: 12px;
  border-bottom: 1px solid #D8D8D8;
  .left-name{
    width: 220px;
    height: 40px;
    img{
      width: 100%;
      height: 100%;
    };
  }
  .right-nav{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .navBox{
      height: 22px;
      width: 100px;
      line-height: 22px;
      text-align: center;
      .navText{
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        color: #313132;
        &.active{
          color: #690409;
        }
      }
    }
  }
}
</style>
