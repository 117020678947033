<template>
  <div class="basicBox">
    <div class="img1">
      <img src="../../static/home/company2.jpg" alt="">
    </div>
    <div class="commondiv">2009年，复盛公营销网络遍布全国，复盛公老字号进行资源重组，2010年成立复盛公药业集团。自成立以来，复盛公致力于打造中国领先的医药产业路由器平台，先后成立品牌事业部、3A事业部、汇通事业部、新宝事业部、电商事业部、大药房连锁事业部、会销事业部、大健康事业部等8个事业部，服务全国近10万家药店零售终端。
    </div>
    <div class="commondiv">2016年10月，复盛公集团被评为山西省医药企业综合实力50强。2017年9月，复盛公六味地黄丸炮制工艺入选山西省非物质文化遗产名录。2018年11月，复盛公集团被山西省科技厅认定为国家高新技术企业。2019年7月，复盛公荣获中国著名商标、中国“3. 15”诚信企业称号。2020年8月，复盛公荣获中国医药行业十佳优秀品牌。2021年3月，复盛公医药产业园投入运营，涵盖中成药、中药饮片、医疗器械、 大健康产业的总部基地正式启动。
    </div>
    <div class="commondiv">2021年-2024年，复盛公集团将依托完善的管理体系、现代化的GSP物流基地、全国性的营销网络实现业绩的快速增长，逐步成长为年营收50亿元的行业龙头企业。
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  components: {
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
<style scoped lang="less">
.basicBox{
  margin: 0;
  width: 70%;
  padding: 80px 15% 100px;
  font-size: 16px;
  line-height: 28px;
  img{
    width: 100%;
    height: 100%;
  }
  .img1{
    width: 100%;
    height: 600px;
    margin-bottom: 50px;
  }
  .commondiv{
    text-indent: 2em;
    margin: 10px 0;
  }
  .putong{
    margin: 30px 0; 
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
  }
  .bottom{
    font-size: 22px;
    margin-top: 40px;
  }
}
</style>