export const items = [
    {
        date:'2021/04/16',
        src: require('/static/news/news1.jpg'),
        title:'晋中市祁县县委书记李军调研考察复盛公医药产业园区',
        subTitle:'2021年4月16日，晋中市祁县县委书记李军到复盛公医药产业园区实地调研考察。祁县经济开发区主任闫锡忠、祁县人大副主任李卫东、副县长董永兴等一行领导随同。',
        content:'2021年4月16日，晋中市祁县县委书记李军到复盛公医药产业园区实地调研考察。祁县经济开发区主任闫锡忠、祁县人大副主任李卫东、副县长董永兴等一行领导随同。李书记深入复盛公集团一号库房、在建办公区、中药产业园，对集团重点项目进度、质量控制、产业规划、运营模式、特色项目以及现阶段的建设情况进行详细了解。复盛公医药产业园区设计规划包括综合电商办公楼、复盛公博物馆、1号仓库、2号仓库、中药产业园、科研楼、实验室等的建筑规划，项目总投资额为33000万元。2020年6月23日复盛公医药产业园区一号库房在县区政府相关部门的高度关注及大力支持下成功封顶，现已投入使用。'
    },
    {
        date:'2021/04/06',
        src: require('/static/news/news2.jpg'),
        title:'燃！复盛公篮球赛丨挑战地心引力 引爆篮球魅力',
        subTitle:'2021年4月6日,风华正茂,暨“篮”正式开赛,谁又会更胜一筹,让我们一起回顾赛果以及那些超燃时刻吧！',
        content:'2021年4月6日,风华正茂,暨“篮”正式开赛,谁又会更胜一筹,让我们一起回顾赛果以及那些超燃时刻吧！3A事业部 vs 品牌事业部,68:64;他们互相鼓励 永不放弃；气沉丹田 精准投篮；争分夺秒 毫不松懈；你追我赶 朝气蓬勃；稳控把球 愈战愈勇；坚持到底 永不服输。'
    },
    {
        date:'2021/03/28',
        src: require('/static/news/news3.jpg'),
        title:'复盛公荣耀绽放海花岛 乘“上行风向”逐“下沉市场”',
        subTitle:'2021年3月28日，以“商品重构，激活市场下沉”为主题的2021西鼎会在海南海花岛国际会议会展中心盛大开幕！复盛公药业集团携旗下100多个中药丸剂盛装亮相，会议期间与连锁药店新老朋友深度洽谈，共谋药品零售产业发展新篇章！',
        content:'2021年3月28日，以“商品重构，激活市场下沉”为主题的2021西鼎会在海南海花岛国际会议会展中心盛大开幕！据悉，本届西鼎会由中康资讯主办，2021西鼎会吸引了全国800多家医药工业参展参会，既有国外合资品牌药企，也有本土领先品牌企业，带来超20000个品规的商品资源，涵盖处方药、OTC药品、保健品、家用医疗器械、医美产品、个人护理品、新健康概念食品等众多品类齐聚现场。复盛公药业集团携旗下100多个中药丸剂盛装亮相，会议期间与连锁药店新老朋友深度洽谈，共谋药品零售产业发展新篇章！西鼎会以“中国健康商品交易大会”为定位，由中康科技主办，致力于打造成一个健康商品丰富、参与企业多、交易量大、交易效率高的商品交易大会。以“聚合、落地、交易、实效”为服务宗旨，缩短供应环节，构建聚合全方位供应链资源的高效交易体系。“复盛公”老字号制丸历史悠久，是全国零售药店品类全、品种多的中药丸剂供应商。无论从科研、服务和经销模式，还是在文化和社会责任方面，都形成了中华商文化价值典范。'
    },
    {
        date:'2020/05/12',
        src: require('/static/news/news4.jpg'),
        title:'至诚至信 义利共生 复盛公药业集团震撼亮相84届药交会',
        subTitle:'2020年5月12-14日，万众瞩目的“第84届全国药品交易会”于近期（5月12-14日）在上海国家会展中心拉开帷幕。中国上海，世人眼里的“东方巴黎”，中国的经济、金融、贸易和航运中心。为期3天的国药会在四叶草国家会展中心（上海）举行。本次会展以全新面貌再次出击，凝心聚力助行业寻找商机，再造医药行业年度盛宴。',
        content:'2020年5月12-14日，万众瞩目的“第84届全国药品交易会”于近期（5月12-14日）在上海国家会展中心拉开帷幕。中国上海，世人眼里的“东方巴黎”，中国的经济、金融、贸易和航运中心。为期3天的国药会在四叶草国家会展中心（上海）举行。本次会展以全新面貌再次出击，凝心聚力助行业寻找商机，再造医药行业年度盛宴。【复盛公】始于公元1755年（乾隆二十年），发源于山西祁县乔家大院，是中国古老的商号之一。作为晋商代表的老字号企业，传承至今260余年。本次药交会，复盛公药业集团携旗下100多个中药系列丸剂、10多个新特药明星产品盛装亮相，集团董事长宋辞先生亲临现场，与客户进行面对面的交流沟通，真诚交谈，共谋药品零售产业发展新篇章！'
    },
    
]

export const businessItems = [
  {
    date:'2021-06-02',
    src: require('/static/news/b3.png'),
    title:'3大品种修订说明书',
    content:'5月31日，国家药品监督管理局发布《关于氟哌啶醇片等品种说明书增加儿童用药信息的公告（2021年第75号）》，有3个品种被要求修订说明书。根据国家药监局的公告，氟哌啶醇片、利培酮口服制剂、氟西汀口服制剂这3大品种需要修订的内容包括适应症和用法用量。（文末附修订说明书详细）近年来国家药监局发布了很多修订药品说明书的公告，被要求说明增加【适应症】、【用法用量】项的品种也很多，尤其是针对儿童和孕妇使用的药品。对于药品上市后还要修改说明书，国家药监局官网曾发文解释：由于药品在上市前的安全性研究中存在客观的局限性，在药品上市前临床研究过程中，受到许多客观因素限制，例如，病例少、研究时间短、试验对象年龄范围窄、用药条件控制较严等。因此，药品不良反应发现上存在时滞现象，这也决定了药品说明书的修改是动态的、不断完善的。药品生产企业应根据药品上市后的安全性、有效性情况及时修改说明书，国家药品监督管理部门也可以根据药品不良反应监测、药品再评价结果等信息要求药品生产企业修改药品说明书。'
  },
  {
      date:'2021-06-01',
      src: require('/static/news/b1.jpg'),
      title:'6月1日起 3个医药法规实施影响所有制药人',
      content:'6月1日开始，药品检查管理办法（试行）、新医疗器械监督管理条例、新专利法开始实施；同时中药材生产质量管理规范（试行）、直接接触药品的包装材料和容器管理办法等4个规章废止。药品检查管理办法（试行）5月28日起施行;办法明确药品监督管理部门依据风险原则制定药品检查计划，确定被检查单位名单、检查内容、检查重点、检查方式、检查要求等，实施风险分级管理，年度检查计划中应当确定对一定比例的被检查单位开展质量管理规范符合性检查。新专利法实施，5月31日前获批新药无专利补偿期。对于2021年5月31日（含该日）以前获得上市许可的新药相关发明专利，药品专利权期限补偿制度不溯及既往。这意味着，在5月31日前获批上市的新药，将失去高达5年的专利权期限补偿。医疗器械监督管理条例于6月1日正式实施。新《条例》发布前的2020年，是在充分摸索论证基础上通过技术指南等层面的文件落实医疗器械领域各项深化改革措施的关键一年。该年度出台的法律文件多是为新《条例》以及相关核心配套规章同步实施所做的重要准备，内容涉及医疗器械全生命周期各环节，具体包括医疗器械拓展性临床试验、真实世界数据应用、注册质量管理体系核查、进口医疗器械境内生产、带量采购、不良事件监测与风险评价、唯一标识、质量抽查检验等，直接影响医疗器械企业各业务部门的操作实践。'
  },
  {
      date:'2021-05-25',
      src: require('/static/news/b2.png'),
      title:'283个常用药退市：维生素C片和罗红霉素胶囊...',
      content:'5月24日，国家药监局发布了《关于注销醋酸甲地孕酮分散片等283个药品注册证书的公告》（以下简称《公告》）。《公告》指出，根据《中华人民共和国药品管理法实施条例》和《药品注册管理办法》的有关规定，国家药品监督管理局决定注销醋酸甲地孕酮分散片等283个药品注册证书。梳理发现，这283个注销药品注册证书的药品注销情形全为依申请注销，这也表示，这些药都是企业主动申请注销的。据了解，自今年7月1日新版《药品注册管理办法》正式实行以来，国家药监局已发布了多则类似的公告，而且企业都是批量注销。笔者梳理发现，此前注销的400个药品中，大多数都是企业都是主动申请注销，个别药品为不予再注册或依法注销。'
  },
  
]

export const zxcy = [
    {
        src: require('/static/products/zxcy/amxlkl.png'),
        name:'阿莫西林颗粒'
    },
    {
        src: require('/static/products/zxcy/ffdsp.png'),
        name:'复方丹参片'
    },
    {
        src: require('/static/products/zxcy/bhszaldpp.png'),
        name:'苯磺酸左氨氯地平片'
    },
    {
        src: require('/static/products/zxcy/wtykp.png'),
        name:'万通炎康片'
    },
    {
        src: require('/static/products/zxcy/zsnp.png'),
        name:'痔速宁片'
    },
    {
        src: require('/static/products/zxcy/htsrjn.png'),
        name:'黄藤素软胶囊'
    },
    {
      src: require('/static/products/zxcy/ffxstrjn.png'),
        name:'复方血栓通软胶囊'
    },
    {
        src: require('/static/products/zxcy/zsjn.png'),
        name:'众生胶囊'
    },
    {
        src: require('/static/products/zxcy/laslgqgkl.png'),
        name:'赖氨酸磷酸氢钙颗粒'
    },
]
export const ylqx = [
  
  {
      src: require('/static/products/ylqx/yiyongtuire.jpg'),
      name:'医用退热贴'
  },
  {
      src: require('/static/products/ylqx/yiyongbahen.png'),
      name:'医用疤痕软膏'
  },
  {
      src: require('/static/products/ylqx/chuangketie.jpg'),
      name:'创口贴'
  },
  {
      src: require('/static/products/ylqx/jjyttt.jpg'),
      name:'颈肩腰腿痛贴'
  },
  {
      src: require('/static/products/ylqx/xexszxt.jpg'),
      name:'小儿消食止泻贴'
  },
  {
      src: require('/static/products/ylqx/yylsyct.jpg'),
      name:'医用冷敷（晕车贴）'
  },
]
export const spbj = [
 
  {
      src: require('/static/products/bjsp/danbaifen.png'),
      name:'蛋白粉'
  },
  {
      src: require('/static/products/bjsp/heizhima.jpg'),
      name:'黑芝麻核桃阿胶糕'
  },
  {
      src: require('/static/products/bjsp/yangru.jpg'),
      name:'羊乳蛋白质粉固体饮料'
  },
  {
      src: require('/static/products/bjsp/xxpgcp.png'),
      name:'鑫玺牌甘草片'
  },
  {
      src: require('/static/products/bjsp/bxpfjrjn.png'),
      name:'贝兴牌蜂胶软胶囊'
  },
  {
      src: require('/static/products/bjsp/ysjf.png'),
      name:'益生菌粉'
  },
]

