<template>
  <div class="basicBox">
    Copyright © 2018-2021 山西复盛公健康药业有限公司 版权所有  <span @click="jumpUrl()"> 备案号：晋ICP备18000185号-4</span> 
  </div>
</template>

<script>
/**
 * 页面最底部那一行
 */
export default {
  name: 'FooterBottom',
  data(){
    return{
    }
  },
  methods:{
    jumpUrl(){
      window.open("https://beian.miit.gov.cn/")
    }
  }
}
</script>

<style scoped lang="less">
.basicBox{
  background: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #242424;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  span{
    padding-left:20px;
    &:hover{
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
