<template>
  <div class="basicBox">
    <div class="item" v-for="(item,index) of items" :key="index">
      <div class="imgBox">
        <img :src="item.src" alt="">
      </div>
      <div class="textBox">
        <div class="title"><b>{{item.title}}</b></div>
        <div class="item">{{item.content}}</div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {items} from '../utils/newItems'
export default {
  name: 'Enterprise',
  components: {
  },
  data(){
    return{
      items,
    }
  },
  methods:{

  }
}
</script>
<style scoped lang="less">
.basicBox{
  margin: 0;
  width: 70%;
  padding: 30px 15% 100px;
  .item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 40px 0;
    .imgBox{
      margin-right: 5%;
      width: 30%;
      height: 200px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .textBox{
      text-indent: 2em;
      width: 65%;
      .title{
        // margin-bottom: 20px;
        font-size: 20px;
        line-height: 32px;
      }
      .item{
        font-size: 16px;
        color: #939396;
        line-height: 28px;
      }
    }
  }
}
</style>