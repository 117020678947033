import {
  Popover,
  // Message,

} from 'element-ui'
const element = {
  install: function(Vue) {
    // Vue.use(Message)
    Vue.use(Popover)
    // Vue.prototype.$message = Message;
  }
}

export default element