<template>
  <div class="imgBox1" @click="toCompany">
    <div class="common img1" v-if="usrc=='1'">
      <div class="floor"></div>
      <div class="name" :style="{color:color}">{{title}}</div>
    </div>
    <div class="common img2" v-if="usrc=='2'">
      <div class="floor"></div>
      <div class="name" :style="{color:color}">{{title}}</div>
    </div>
    <div class="common img3" v-if="usrc=='3'">
      <div class="floor"></div>
      <div class="name" :style="{color:color}">{{title}}</div>
    </div>
  </div>
</template>

<script>
/**
 * 图片盒子1
 * 传入index，展示对应的盒子；由于无法获取传入的本地图片路径，故采用此方法控制显示的图片
 * 只定位显示一组文字
 */
export default {
  name: 'imgBox1',
  props:{
    title:{
      type: String
    },
    usrc:{
      type: String
    },
    color:{
      type:String
    }
  },
  data(){
    return{
    }
  },
  methods:{
    toCompany(){
      if(this.usrc==='1'){
        this.$router.push('/intro')
        return
      }
      if(this.usrc==='2'){
        this.$router.push('/process')
        return
      }
      if(this.usrc==='3'){
        this.$router.push('/culture')
        return
      }
    }
  }
}
</script>

<style scoped lang="less">
.imgBox1{
  width: 33%;
  height: 230px;
  .common{
    position: relative;
    width: 100%;
    height: 100%;
    .floor{
      width: 100%;
      height: 100%;
      background:rgba(0,0,0,0.4);
    }
    .name{
      position: absolute;
      bottom: 30px;
      left: 30px;
      // width: 104px;
      height: 36px;
      font-size: 26px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 36px;
    }
  }
  .img1{
    background:url('../../static/home/company2.jpg') no-repeat;
    background-size: 100% 100%;
  }
  .img2{
    background:url('../../static/company/jincheng1.png') no-repeat;
    background-size: 100% 100%;
  }
  .img3{
    background:url('../../static/company/wenhua2.png') no-repeat;
    background-size: 100% 100%;
  }
  
}
</style>
