<template>
  <div class="basicBox">
    <div class="img1">
      <img src="../../static/company/wenhua2.png" alt="">
    </div>
    <Title title="企业文化" fontSize="36px" color="#313132" height="50px" lineHeight="50px" class="commonLeft"></Title>
    <div class="commondiv">企业使命：弘扬百年晋商精神  一切只为人民健康</div>
    <div class="commondiv">企业愿景：致力成为中国中药丸剂第一品牌打造中国领先的医药产业路由器平台</div>
    <div class="commondiv">企业价值观：利他 互信 共享</div>
    <div class="commondiv">企业文化：以义制利 以人为本 诚信务实 分享共赢</div>

    
    
  </div>
</template>

<script>
import Title from 'components/Title.vue'
export default {
  name: 'Culture',
  components: {
    Title
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
<style scoped lang="less">
.basicBox{
  margin: 0;
  width: 70%;
  padding: 80px 15% 100px;
  font-size: 16px;
  line-height: 28px;
  img{
    width: 100%;
    height: 100%;
  }
  .img1{
    width: 100%;
    height: 600px;
    margin-bottom: 50px;
  }
  .commondiv{
    margin: 10px 0;
  }
  
}
</style>