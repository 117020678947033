<template>
  <div class="basicBox">
    <div class="common" v-for="(item,index) of this.navs" :key="index">
      <Title :title="item.name" fontSize="20px" class="title"></Title>
      <Item :item="v.name" class="item" v-for="(v,index1) of item.children" :key="index1" :toUrl="v.value"></Item>
    </div>
  </div>
</template>

<script>
/**
 * 页面底部
 */
import Title from 'components/Title.vue'
import Item from 'components/Item.vue'
export default {
  name: 'Footer',
  components: {
    Title,
    Item
  },
  data(){
    return{
      navs:[
        {value:'',name:'首页', show:false, children:[
          {value:'',name:'首页'},
        ]},
        {value:'company',name:'公司简介', show:false,children: [
          {value:'intro',name:'简介'},
          {value:'process',name:'历程'},
          {value:'culture',name:'企业文化'},
        ]},
        {value:'news',name:'新闻动态', show:false,children: [
          {value:'enterprise',name:'企业新闻'},
          {value:'business',name:'行业资讯'},
        ]},
        {value:'products',name:'商品展示', show:false,children: [
          {value:'zxcy',name:'中西成药'},
          {value:'ylqx',name:'医疗器械'},
          {value:'spbj',name:'食品保健'},
        ]},
        {value:'contact',name:'联系我们',show:false,children:[
          {value:'contact',name:'24小时服务热线：400-100-8189'},
        ]}
      ],
    }
  },
  methods:{
  }
}
</script>

<style scoped lang="less">
.basicBox{
  background: #343434;
  padding: 60px 15%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  img{
    width: 100%;
    height: 100%;
  }
  .common{
    padding: 0;
    margin: 0;
    text-align: left;
    .title{
      margin-bottom: 14px;
    }
    .item{
      cursor: pointer;
    }
  }
  .entrance{
    cursor: pointer;
    .item:hover{
      text-decoration: underline;
    }
  }
  .focus{
    text-align: center;
    .code{
      margin-top: 30px;
      width: 200px;
      height: 200px;
    }
  }
}
</style>
