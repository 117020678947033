<template>
  <div class="basicBox">
    <div class="img1">
      <img src="../../static/company/jincheng1.png" alt="">
    </div>
    
    <div class="commondiv">【复盛公】始创于公元1755年（乾隆二十年），是中国古老的商号之一。据考证，乾隆二年（公元1737年），山西祁县人乔贵发与徐沟人秦肇庆二人到内蒙萨拉齐老官村谋生，后迁到包头村，含辛茹苦经营草料、豆芽等，赚得一份产业。乾隆二十年（公元1755年），正式开设了广盛公货铺，经营粮食、杂货等。嘉庆二十三年（公元1818年），广盛公买卖因变欠债为盈余，重新兴旺，遂改号复盛公，揭开了晋商最为辉煌的一页。</div>
    <div class="commondiv">【复盛公】商号当年除粮盘钱盘之外，还开设了当铺、估衣铺、药铺、钱庄、票号，商号发展遍布全国。包头有复盛公、复盛西、复盛全三大号，后三大号又分为乔家独资的复字德、永、裕记、通和店、广顺恒等等。而在归化城（呼和浩特市），有大德店、德兴店、法中庸等等。在山西祁县，有大德通、大德恒、亿中恒、万川汇、广兴泰。在太原则有合资的晋泉源票号，太谷有溥晋银号等等，其中不少字号在全国各大城市都设有分号。</div>
    <div class="commondiv">【复盛公】商号历经七代人苦心经营，秉承“信”字为本的商业准则，最终实现了“货通天下”，铸就了“复盛公”的金字招牌。当年在中国商界和金融界纵横捭阖，呼风唤雨，其财富之多，如今数以百亿元计，其经营规模之大，持续时间之长，社会影响之广，堪称中国商界、金融界之奇观，故有“先有复盛公，后有包头城”之美誉。</div>
    <div class="commondiv">【复盛公】作为晋商代表的老字号企业，200多年来历经风雨变迁。其经营管理之道，一直被古今中外社会各界人士广为传颂。1926年战乱四起，复盛公各商号被摊派军饷元气大伤，业务逐渐萎缩。1937年包头被日军侵占，复盛公商号遭受重创，奄奄一息。1951年公私合营，复盛公停止营业，辉煌达两个多世纪的复盛公商号正式关闭。
    </div>
    <div class="commondiv">2009年，【复盛公】老字号进行资源整合，与美国摩盛投资集团强强携手成立【复盛公药业集团】，正式进入生物医药健康产业，旨在打造“复字号”系列国药精品，为人类带来健康和财富。集团致力于中药现代化、规模化发展，总投资达3亿元，涵盖科研、种植、生产、营销、物流，是山西省重点支持、实施“转型发展、跨越发展”的高新技术企业之一。</div>
    <div class="commondiv">现在的【复盛公】商号是一家现代化的药业集团，拥有完善的管理体系、现代化的GMP生产基地、GSP物流基地、全国性健全的营销网络，同时拥有优质配套的物流系统，有口皆碑的商业信誉以及有目共睹的知名度。核心业务从全国总代理拓展到医药工业、终端营销以及电子商务，并致力于生产、经营，服务创造价值。</div>
    <div class="commondiv">目前复盛公集团拥有自主【复盛公】品牌以六味地黄丸为代表的明目地黄丸、桂附地黄丸、杞菊地黄丸、香砂养胃丸、保和丸、逍遥丸等12个浓缩丸系列产品，全国总代理品种有复方石韦胶囊、众生胶囊、附桂骨痛胶囊、熊胆痔灵栓、元胡胃舒片等38个特色品种，与众多企业展开深度合作，不断深化产业供需格局，搭建“资源整合、优势互补”的合作平台。</div>
    <div class="commondiv">复盛公集团始终坚持“工商联盟、统一品牌、服务终端、共赢发展”的营销理念，以地市县为销售单元，传递医药科学知识，传播健康理念，满足消费者价值。积极参与国家医疗体制改革，积极参加各地医疗采购招标。</div>
    <div class="commondiv">“虔诚虽无人见，存心自有天知”。复盛公集团秉承晋商“诚信立本，货通天下”的发展理念，愿与各界朋友精诚合作，共同将【复盛公】这个具有两百多年历史的老字号在医药市场中再现辉煌！</div>
    <div class="commondiv bottom"><b><i>百年复盛公，诚信制良药！ </i></b></div>
    
  </div>
</template>

<script>
export default {
  name: 'Process',
  components: {
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
<style scoped lang="less">
.basicBox{
  margin: 0;
  width: 70%;
  padding: 80px 15% 100px;
  font-size: 16px;
  line-height: 28px;
  img{
    width: 100%;
    height: 100%;
  }
  .img1{
    width: 100%;
    height: 600px;
    margin-bottom: 50px;
  }
  .commondiv{
    text-indent: 2em;
    margin: 10px 0;
  }
  .putong{
    margin: 30px 0; 
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
  }
  .bottom{
    font-size: 22px;
    margin-top: 40px;
  }
}
</style>