<template>
  <div class="comBox" @click="toNews">
    <div class="dateShow">
      <div class="monthDay">{{item.monthDay}}</div>
      <div class="years">{{item.years}}</div>
    </div>
    <div class="itemShow">
      <Title :title="item.title" fontSize="20px" color="#313132" height="28px" lineHeight="28px" class="title"></Title>
      <ItemNew :item="item.subTitle" fontSize="14px" color="#939396" lineHeight="20px" class="item" overStyle="2"></ItemNew>
    </div>
  </div>
</template>

<script>
/**
 * 新闻条目
 * 传入id，获取对应内容
 * 左边是时间，右边是标题和大概内容
 */
import {items} from '../utils/newItems'
import Title from 'components/Title.vue'
import ItemNew from 'components/ItemNew.vue'
export default {
  name: 'NewsItem',
  components: {
    Title,
    ItemNew,
  },
  props:{
    id:{
      type: String
    },
  },
  watch:{
    id:{
      handler(val){
        this.item = Object.assign({},this.items[val]) 
        this.item.years = this.item.date.substr(0,4)
        this.item.monthDay = this.item.date.substr(5,5)
      },
      immediate:true
    }
  },
  data(){
    return{
      item:{},
      items,
    }
  },
  methods: {
    toNews(){
      // this.$router.push('/enterprise')
    }
  },
}
</script>

<style scoped lang="less">
.comBox{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  .dateShow{
    width: 82px;
    margin-right: 20px;
    padding: 5px 20px 5px 0;
    border-right: 1px solid #E6E6E6;
    text-align: right;
    .monthDay{
      height: 42px;
      font-size: 30px;
      font-weight: 400;
      color: #313132;
      line-height: 42px;
    }
    .years{
      height: 22px;
      font-size: 16px;
      font-weight: 400;
      color: #313132;
      line-height: 22px;
    }
  }
  .itemShow{
    width: 80%;
    .title{
      margin-bottom: 4px;
    }
  }
}
</style>
