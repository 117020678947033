<template>
  <div class="conBox" :style="{fontSize:fontSize,color:color,lineHeight:lineHeight,height:height}" :class="overStyle?'overHandle':''">{{title}}
  </div>
</template>

<script>
/**
 * 标题
 * 支持内容、颜色，字体大小、高，行高，及单行省略，单行省略是默认
 */
export default {
  name: 'Title',
  props:{
    overStyle:{
      type:Boolean,
      default:true
    },
    title:{
      type:String
    },
    color:{
      type:String,
      default:'#FFFFFF'
    },
    fontSize:{
      type:String,
      default:'20px'
    },
    height:{
      type:String,
      default:'28px'
    },
    lineHeight:{
      type:String,
      default:'28px'
    }
  }
}
</script>

<style scoped lang="less">
.conBox{
  position: relative;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
  z-index: 5;
}
.overHandle{
  width: 100%;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
</style>
