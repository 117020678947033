import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css';
import element from './element/index'
Vue.use(element)

Vue.config.productionTip = false
document.title = '山西复盛公健康药业'
// const navRouters = ['/','/company','/news','/products','/contact']
router.beforeEach((to, from, next) => {
  
  
  let item = to.path.substring(1)
  
  store.commit('setCurPage', item)
  next();
})
new Vue({
  router,
  store,
  render: h => h(App)
  
}).$mount('#app')
