import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: () => import('views/Home.vue')
  },
  // 简介
  {
    path: '/intro',
    name: 'Intro',
    component: () => import('views/Intro.vue')
  },
  // 历程
  {
    path: '/process',
    name: 'Process',
    component: () => import('views/Process.vue')
  },
  // 企业文化
  {
    path: '/culture',
    name: 'Culture',
    component: () => import('views/Culture.vue')
  },
  // 企业新闻
  {
    path: '/enterprise',
    name: 'Enterprise',
    component: () => import('views/Enterprise.vue')
  },
  // 行业资讯
  {
    path: '/business',
    name: 'Business',
    component: () => import('views/Business.vue')
  },
  // 中西成药
  {
    path: '/zxcy',
    name: 'Zxcy',
    component: () => import('views/Zxcy.vue')
  },
  // 医疗器械
  {
    path: '/ylqx',
    name: 'Ylqx',
    component: () => import('views/Ylqx.vue')
  },
  // 食品保健
  {
    path: '/spbj',
    name: 'Spbj',
    component: () => import('views/Spbj.vue')
  },
  //联系我们
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('views/Contact.vue')
  },
  
]

const router = new VueRouter({
  linkActiveClass:'active',
  routes,
  scrollBehavior () {
    return {y:0}
  }
})
//重复点击路由报错解决方法
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
