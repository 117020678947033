import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router/index.js';
// import { Message, MessageBox,Loading  } from "element-ui";
Vue.use(Vuex)

const state = {
  curPage:'company1'
}
const mutations = {
  setCurPage(state,val){
    state.curPage = val
  }
}
const actions = {
  
  
}
const getters = {
  
}
const Store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
export default Store